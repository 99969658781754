import { useCallback } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { createSelector } from 'reselect';
import { useSelector } from 'react-redux';
import moment from 'moment-timezone';

import { getEvent, getId as getEventId } from '../state/event';
import { update as updateConf } from '../services/config/api';
import { checkPortal } from '../state/system';
import { useConfigKeysQuery, useConfigQuery } from '../services/config';

enum FeatureFlagStatus {
  Enabled = '1',
  Disabled = '0',
}

// VALID_NULLABLE_CONFIG_KEYS is the list of configuration keys that can have a null
// as valid value.
// These configurations should not be considered as configs with missing values and
// be replaced with default value if the value is null.
//
// One good example is `event_max_duration_days` which will have a null value if the
// event does not have a max duration set; otherwise it will have a number value.
const VALID_NULLABLE_CONFIG_KEYS = ['event_max_duration_days', 'reg_open_period'];

const selector = createSelector(
  getEventId,
  getEvent,
  checkPortal,
  (eventId, { timezone }, { isExperiencePortal }) => ({
    eventId,
    timezone,
    isExperiencePortal,
  })
);

const useConfig = () => {
  const { eventId, timezone, isExperiencePortal } = useSelector(selector);
  const queryClient = useQueryClient();
  const keysQuery = useConfigKeysQuery([], { enabled: isExperiencePortal });
  const configQuery = useConfigQuery([eventId], { enabled: !!eventId });

  // react-query return isLoading as true when the enabled flag is false
  // so in the case of CompanyPortal the keysQuery.isLoading will always return true
  // hence added one more check - !isExperiencePortal
  const loading = (keysQuery.isLoading && isExperiencePortal) || configQuery.isLoading;
  const keys = keysQuery.data;
  const conf = configQuery.data;

  // gets the value of a record from the configuration array stored in the state.
  // in case of key not being existent in the configuration object, it looks up
  // in the keys store and returns the default value (returns the first one when
  // there are multiple default values), if it doesn't exist in the default keys
  // array either then it return null
  const get = useCallback(
    (key: string) => {
      const targetConfig = conf?.find(c => c.key === key);
      const targetKey = keys?.find(k => k.key === key);

      if (typeof targetConfig === 'undefined' && typeof targetKey === 'undefined') {
        return null;
      }

      // some config keys may have null as valid value; if this key is among those
      // and the value is null, return null - and do not use the default value
      if (VALID_NULLABLE_CONFIG_KEYS.includes(key) && targetConfig?.value === null) {
        return null;
      }

      if (targetKey?.type === 'datetime_range' && targetConfig?.value) {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        const { start_datetime, end_datetime } = JSON.parse(targetConfig.value);
        return [moment.tz(start_datetime, timezone), moment.tz(end_datetime, timezone)];
      }

      // if value property exists in the target returns that, other wise look for the
      // default property, if that exists then splits the value of default using
      // `,` character so that it will return the first value in case there are multiple
      // default values; in rare scenario of neither value nor default property being
      // defined, return null
      const confValue = targetConfig?.value;
      const keyValue = targetKey?.default?.split(',')[0];

      if (confValue !== undefined) {
        return confValue;
      }

      if (keyValue !== undefined) {
        return keyValue;
      }

      return null;
    },
    [keys, conf, timezone]
  );

  const find = useCallback((key: string) => conf.find(c => c.key === key), [conf]);

  // asynchronous function to either update a configuration object or create a new one
  // if it does not exist; returns the newly created/update record
  const set = useCallback(
    async (key: string, value: any) => {
      const target = conf.find(c => c.key === key);

      if (typeof target === 'undefined') {
        throw new Error(`invalid configuration key ${key}`);
      }
      // if target is not `undefined` then it already exists in the configurations
      // so update the its value with provided value
      const record = await updateConf(eventId, target.id, { ...target, value });

      queryClient.invalidateQueries({ queryKey: ['config'] });

      return record;
    },
    [conf, eventId, queryClient]
  );

  /**
   * @description checks if the provided configuration keys are enabled
   * @param {String} getKeys - configuration key to check
   * @param {String, String, String} getKeys - n number of configuration keys to check
   * @returns {boolean} true if and only if all the config keys passed are enabled.
   * return `true` if it exists AND its value is set to `1`
   */
  const isEnabled = useCallback(
    (...allKeys: string[]) => {
      const values = allKeys.map(get);
      return values?.some(value => value !== null && value === FeatureFlagStatus.Enabled);
    },
    [get]
  );

  /**
   * @description checks if any provided configuration key is disabled
   * @param {String} getKeys - configuration key to check
   * @param {String, String, String} getKeys - n number of configuration keys to check
   * @returns {boolean} true if any config is false.
   * return `true` if it exists AND its value is set to `0`
   */
  const isDisabled = useCallback(
    (...allKeys: string[]) => {
      const values = allKeys.map(get);

      return values?.some(v => v === null || v === FeatureFlagStatus.Disabled);
    },
    [get]
  );

  // returns all enabled configuration settings
  const enabled = useCallback(() => conf.filter(c => c?.value === FeatureFlagStatus.Enabled), [
    conf,
  ]);

  // returns all disabled configuration settings
  const disabled = useCallback(() => conf.filter(c => c?.value === FeatureFlagStatus.Disabled), [
    conf,
  ]);

  return { get, find, set, isEnabled, isDisabled, enabled, disabled, loading };
};

export default useConfig;
