/* eslint-disable max-classes-per-file */
import axios, { AxiosResponse } from 'axios';
import camelCaseKeys from 'camelcase-keys';
import snakeCaseKeys from 'snakecase-keys';

const IGNORED_403_ERROR_CODE = 'sso_disabled_invalid_operation';

enum Err {
  Network = 'E_NETWORK',
  UnknownServerException = 'E_UNKNOWN_SERVER_EXCEPTION',
}

export class RequestError extends Error {
  public headers: Record<string, string>;

  public type: string;

  constructor(message, request) {
    super();

    this.message = message;
    this.stack = new Error().stack;
    this.name = this.constructor.name;

    this.headers = request.headers;

    this.type = this.message === 'Network Error' ? Err.Network : Err.UnknownServerException;
  }
}

export class ResponseError extends Error {
  public status: number;

  public statusText: string;

  public headers: Record<string, string>;

  public errors: any[];

  public types: string[];

  public type: string;

  constructor(message, response: AxiosResponse<any>) {
    super();

    this.message = message;
    this.stack = new Error().stack;
    this.name = this.constructor.name;

    const { status, statusText, headers } = response;

    this.status = status;
    this.statusText = statusText;
    this.headers = headers;

    this.errors = response?.data?.errors ?? [];
    this.types = this.errors.map(({ code }) => code);

    this.type =
      this.types?.[0] ??
      (this.message === 'Network Error' ? Err.Network : Err.UnknownServerException);
  }
}

const DEFAULT_HEADERS = {
  Accept: 'application/vnd.eventmobi+json; version=p.6',
  // Flux has read/write replicas so the result of POST/PATCH/DELETE call is not
  // immediately reflected on the subsequent GET call. To read directly from the
  // master database, we have the following header set to `true` for most of our
  // API calls until we have a permanent solution.
  'x-use-master-db': true,
};

export default (baseURL, headers = {}, withCredentials = true, ...options) => {
  const api = axios.create({
    baseURL,
    headers: { ...DEFAULT_HEADERS, ...headers },
    withCredentials,
    ...options,
  });

  api.interceptors.request.use(
    request => {
      // anything that needs to be done before request is sent
      // can be done here
      if (request.data) {
        Object.assign(request, {
          data: snakeCaseKeys(request.data, { deep: true }),
        });
      }
      if (request.params && request.params instanceof URLSearchParams) {
        Object.assign(request, {
          params: snakeCaseKeys(Object.fromEntries([...request.params])),
        });
      }
      if (request.params && !(request.params instanceof URLSearchParams)) {
        Object.assign(request, {
          params: snakeCaseKeys(request.params, { deep: true }),
        });
      }
      return request;
    },
    error => Promise.reject(new RequestError(error.message, error.request))
  );

  // Add a response interceptor
  api.interceptors.response.use(
    response => {
      // Convert the response data from snake_case to camelCase
      Object.assign(response, {
        data: camelCaseKeys(response.data, { deep: true }),
      });

      // Copy CMS-style pagination into UAPI-style pagination
      // https://eventmobi.slack.com/archives/C01183LHRRR/p1709758136464999
      if (typeof response.data.meta?.pagination?.totalRecordCount !== 'undefined') {
        const { pagination } = response.data.meta;
        pagination.totalItemsCount = pagination.totalRecordCount;
        pagination.pageItemsCount = pagination.returnedRecordCount;
      }

      return response;
    },
    async error => {
      const status = error?.response?.status || 200;
      const responseURL = error?.request?.responseURL ?? '';
      const fluxErrorCode = error.response?.data?.errors?.[0]?.code;
      const isAuthRequest = /\/api(\/.*)?\/(login|logout)/.test(responseURL);

      if (!isAuthRequest) {
        const currentPath = `${window.location.pathname}${encodeURIComponent(
          window.location.search
        )}`;
        if (status === 401) {
          window.location.replace(`/unauthorized?next=${currentPath}`);
          // TODO: Remove the IGNORED_403_ERROR_CODE as part of EXP-16358
        } else if (status === 403 && fluxErrorCode !== IGNORED_403_ERROR_CODE) {
          window.location.replace('/forbidden');
        }
      }

      // If the original error has response data, generate a ResponseError from it
      return Promise.reject(
        error.response ? new ResponseError(error.message, error.response) : error
      );
    }
  );

  return api;
};
