import React from 'react';
import { t } from 'ttag';
import { Button } from 'antd';

import sessionsImage from '../../assets/sessions.svg';
import useTranslation from '../../hooks/useTranslation';
import Layout from './Layout';

interface Props {
  scoped?: boolean;
  ctaText?: string;
  ctaHref?: string;
  title?: string;
  body?: string;
  onClick?: () => void;
}

const HTTP403 = ({
  scoped = false,
  ctaText = null,
  ctaHref = '/',
  title = null,
  body = null,
  onClick = null,
}: Props) => {
  useTranslation();
  return (
    <Layout
      scoped={scoped}
      title={title ?? t`Unauthorized Access`}
      body={body ?? t`Sorry, you do not have access to this page or resource.`}
      image={sessionsImage}
      support={false}
    >
      <Button type="primary" onClick={onClick || (() => window.location.replace(ctaHref))}>
        {ctaText ?? t`Go to Homepage`}
      </Button>
    </Layout>
  );
};

export default HTTP403;
