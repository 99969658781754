import { createContext, useContext } from 'react';

export const SIDE_NAV_PROPERTY = '--side-nav-width';

export const SIDE_NAV_WIDTH = {
  Expanded: '230px',
  Collapsed: '48px',
};

export const SIDE_NAV_TRANSITION_CURVE = '0.3s cubic-bezier(0.2, 0, 0, 1)';

export const SideNavExpandedContext = createContext(true);

export const useSideNavExpanded = () => {
  const context = useContext(SideNavExpandedContext);
  if (context === undefined) {
    throw new Error('useSideNavExpanded must be used within a SideNavExpandedProvider');
  }
  return context;
};
