/* global DEVELOPMENT, STAGE */
/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

export interface EventState {
  id: string;
  name: string;
  shortcode: string;
  description: string;
  timeFormat: '12 Hour' | '24 Hour';
  timezone: string;

  creationDate: string;
  startDate: string;
  endDate: string;

  language: string;
  alternativeLanguages: string[];

  locationAddress: string;
  locationName: string;

  partnerId: number;
  organizationId: string;
  eventType: 'default' | string; // TODO: Set this to the restricted types
  eventWebsite: string;
  eventIconImageId: string;
  isTrial: boolean;
  multilingualEventApp: boolean;
  perPersonLimit: number;
  supportEmailAddress: string;
  outboundEmailAddress: string | null;
  useCustomOutboundEmailAddress: boolean;
  customDomainEnabled: boolean;
  customDomainName: string | null;
  customDomainStatus: string | null;

  // TODO: Figure this out
  configurations: Record<string, any>;
  eventUrls: string[];
}

const generateEventUrls = (eventCode, customDomain = undefined) => {
  const urls = [`https://eventmobi.com/${eventCode}`];

  if (customDomain) {
    urls.push(`https://${customDomain}/${eventCode}`);
  }

  if (DEVELOPMENT) {
    urls.push(`https://app.localhost/${eventCode}`);
  }

  if (STAGE) {
    urls.push(`https://release.eventmobi.com/${eventCode}`);
  }

  return urls;
};

const initialState: Partial<EventState> = {
  id: null,
  name: null,
  shortcode: null,
  description: null,

  timeFormat: '12 Hour',
  timezone: 'America/New_York',

  creationDate: null,
  startDate: null,
  endDate: null,

  language: 'english',
  alternativeLanguages: [],

  locationAddress: null,
  locationName: null,

  partnerId: 0,
  organizationId: null,
  eventType: null,
  eventWebsite: null,
  eventIconImageId: null,
  isTrial: false,
  multilingualEventApp: null,
  perPersonLimit: null,
  supportEmailAddress: null,
  outboundEmailAddress: null,
  useCustomOutboundEmailAddress: null,
  customDomainEnabled: false,
  customDomainName: null,
  customDomainStatus: null,

  configurations: {},
  eventUrls: [],
};

const slice = createSlice({
  name: 'event',
  initialState,
  reducers: {
    setEvent: {
      prepare(event: any = {}) {
        const payload = {
          id: event.id,
          name: event.name,
          shortcode: event.shortcode,
          description: event.description,
          timeFormat: event.timeFormat,
          timezone: event.configuration?.timezone,
          creationDate: event.creationDate,
          startDate: event.startDate,
          endDate: event.endDate,
          language: event.language,
          alternativeLanguages: event.alternativeLanguages,
          locationAddress: event.locationAddress,
          locationName: event.locationName,
          partnerId: event.partnerId,
          organizationId: event.organizationId,
          eventType: event.eventType,
          eventWebsite: event.eventWebsite,
          eventIconImageId: event.eventIconImageId,
          isTrial: event.isTrial,
          multilingualEventApp: event.multilingualEventapp, // fixed the case
          perPersonLimit: event.perPersonLimit,
          supportEmailAddress: event.supportEmailAddress,
          outboundEmailAddress: event.outboundEmailAddress,
          useCustomOutboundEmailAddress: event.useCustomOutboundEmailAddress,
          customDomainEnabled: event.customDomainEnabled,
          customDomainName: event.customDomainName,
          customDomainStatus: event.customDomainStatus,
        };

        return { payload, meta: null, error: null };
      },
      reducer(state, { payload }) {
        state.id = payload.id;
        state.name = payload.name;
        state.shortcode = payload.shortcode;
        state.description = payload.description;
        state.timeFormat = payload.timeFormat;
        state.timezone = payload.timezone;
        state.creationDate = payload.creationDate;
        state.startDate = payload.startDate;
        state.endDate = payload.endDate;
        state.language = payload.language;
        state.alternativeLanguages = payload.alternativeLanguages;
        state.locationAddress = payload.locationAddress;
        state.locationName = payload.locationName;
        state.partnerId = payload.partnerId;
        state.organizationId = payload.organizationId;
        state.eventType = payload.eventType;
        state.eventWebsite = payload.eventWebsite;
        state.eventIconImageId = payload.eventIconImageId;
        state.isTrial = payload.isTrial;
        state.multilingualEventApp = payload.multilingualEventApp;
        state.perPersonLimit = payload.perPersonLimit;
        state.supportEmailAddress = payload.supportEmailAddress;
        state.outboundEmailAddress = payload.outboundEmailAddress;
        state.useCustomOutboundEmailAddress = payload.useCustomOutboundEmailAddress;
        state.customDomainEnabled = payload.customDomainEnabled;
        state.customDomainName = payload.customDomainName;
        state.customDomainStatus = payload.customDomainStatus;
      },
    },
    setConfigurations: {
      prepare(defaults = [], configurations = []) {
        const payload = {};

        // eslint-disable-next-line no-restricted-syntax
        for (const {
          key,
          default: defaultValue,
          max_expiry_in_days: maxExpiryInDays,
          description,
          display_name: displayName,
          id: keyId,
          type,
          validation,
        } of defaults) {
          const next = {
            keyId,
            type,
            maxExpiryInDays,
            description,
            displayName,
            validation,
            value: null,
            options: null,
            defaultValue,
          };

          switch (type) {
            case 'boolean': {
              next.defaultValue = defaultValue === '1';
              break;
            }
            case 'integer': {
              next.defaultValue = Number.parseInt(defaultValue, 10);
              break;
            }
            case 'radio':
            case 'checkbox': {
              next.options = defaultValue.split(',');
              next.defaultValue = null;
              break;
            }
            default:
              next.defaultValue = defaultValue;
          }

          payload[key] = next;
        }

        // we don't use keys `timeFormat` and `is_document_upload_enabled` with the `owner_type` value `agenda`
        // TODO: this isn't actually filtering because its not using the returned array
        configurations.filter(
          item =>
            !(
              item.owner_type === 'agenda' &&
              ['timeFormat', 'is_document_upload_enabled'].includes(item.key)
            )
        );

        // eslint-disable-next-line no-restricted-syntax
        for (const { id, key, value } of configurations) {
          payload[key].id = id;
          switch (payload[key].type) {
            case 'boolean': {
              payload[key].value = value === '1';
              break;
            }
            case 'integer': {
              payload[key].value = Number.parseInt(value, 10);
              break;
            }
            default:
              payload[key].value = value;
          }
        }

        return { payload, meta: null, error: null };
      },
      reducer(state, { payload }) {
        state.configurations = payload;
      },
    },
    setConfiguration(state, { payload }) {
      const { key, value } = payload;

      state.configurations[key].value = value;
    },
    setCustomDomain(state, { payload }) {
      const isCustomDomainEnabled = state.configurations.is_custom_domain_enabled?.value === true;
      if (isCustomDomainEnabled) {
        state.eventUrls = generateEventUrls(state.shortcode, payload);
      } else {
        state.eventUrls = generateEventUrls(state.shortcode);
      }
    },
  },
});

export default slice.reducer;

export const getEvent = (state: EventState): EventState => state[slice.name];

export const getId = (state: EventState): string => state[slice.name]?.id;
export const getName = (state: EventState) => state[slice.name]?.name;
export const getShortcode = (state: EventState) => state[slice.name]?.shortcode;
export const getDescription = (state: EventState) => state[slice.name]?.description;
export const getTimezone = (state: EventState) => state[slice.name]?.timezone;
export const getTimeFormat = (state: EventState) => state[slice.name]?.timeFormat;
export const getLanguage = (state: EventState) => state[slice.name]?.language;
export const getEventUrls = (state: EventState) => state[slice.name]?.eventUrls;
export const getSupportEmailAddress = (state: EventState) => state[slice.name]?.supportEmailAddress;

export const getLanguages = (state: EventState) => {
  const [language, alternativeLanguages] = state[slice.name];

  return [language, ...alternativeLanguages];
};

export const getDates = (state: EventState) => {
  const { startDate: start, endDate: end } = state[slice.name];

  return { start, end };
};

export const getLocation = (state: EventState) => {
  const { locationAddress: address, locationName: name } = state[slice.name];

  return { address, name };
};

export const getConfigurations = (state: EventState) => state[slice.name].configurations;

export const getConfiguration = (state, key) => {
  const { configurations } = state[slice.name];

  return configurations?.[key]?.value ?? configurations?.[key]?.defaultValue;
};

export const isEnabled = (state, key) => {
  // regex will check for any uppercase letters
  if (/[A-Z]/.test(key)) {
    // eslint-disable-next-line no-console
    console.warn('isEnabled only accepts snake_case config keys');
  }

  return getConfiguration(state, key) === true;
};

export const isDisabled = (state, key) => getConfiguration(state, key) !== true;

export const { setEvent, setConfigurations, setConfiguration, setCustomDomain } = slice.actions;
